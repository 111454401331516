


import { Component, Mixins, Watch } from 'vue-property-decorator'

import { Inject, logger } from '../../../../support'
import {
  ISiteService,
  OrderData,
  OrderResponse,
  PaymentMethodCode,
  SiteServiceType
} from '../../../../contexts'

import { AuthMixin } from '../../../auth/shared'

import { UserMixin } from '../../../profile/shared'

import { BaseCartMixin } from '../../shared/mixins/base-cart.mixin'
import { CheckoutPayload } from '../../contracts'
import { CheckoutServiceType, ICheckoutService } from '../../services/checkout'
import { ORDER_KEY } from '../../services/cart'

import { RouteName } from '../../routes'

import {
  translateCartToCheckoutPayload
} from '../../helpers/checkout.helpers'
import { translateToCartItem } from '../../molecules/CartItem/CartItem.helpers'
import { EventbusType, IEventbus } from '@movecloser/front-core'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<CheckoutBase>({
  name: 'CheckoutBase'
})
export class CheckoutBase extends Mixins(AuthMixin, BaseCartMixin, UserMixin) {
  @Inject(CheckoutServiceType)
  protected readonly checkoutService!: ICheckoutService

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(SiteServiceType)
  protected readonly siteService!: ISiteService

  public agreements: string[] = []
  public checkoutPayload: CheckoutPayload | null = null
  public globalError: string | null = null
  public isLoading: boolean = false
  public isSubmitted: boolean = false
  public newsletter: boolean = false
  public pages: Record<string, string> | null = null
  public placingOrder: boolean = false
  public showAgreementWarning: boolean = false

  public get discount (): number | undefined {
    return this.cart?.getTotalDiscount()
  }

  public get isReady (): boolean {
    return !!this.cart && this.cart.isSet() && !!this.checkoutPayload
  }

  public get step (): number {
    return parseInt(`${this.$route.query.step}`) ?? 1
  }

  public set step (step: number) {
    this.$router.push({ name: `${this.$route.name}`, query: { step: `${step}` } })
  }

  public get tax (): number {
    return this.cart?.getTotalTax() ?? 0
  }

  public get shipping (): number {
    return this.cart?.getShippingPrice() ?? 0
  }

  public get shouldSuggestNewsletter (): boolean {
    if (!this.checkoutPayload) {
      return true
    }

    return !this.checkoutPayload.isUserLoggedIn ||
      (this.checkoutPayload.isUserLoggedIn && !!this.user && !this.user.isSubscribed)
  }

  public get subtotal (): number {
    return this.cart?.getSubtotalPrice() ?? 0
  }

  public get total (): number {
    return this.cart?.getTotalPrice() ?? 0
  }

  public onError (message: string) {
    this.globalError = message
  }

  public async placeOrder (): Promise<void> {
    if (!this.cart?.isSet()) {
      return
    }

    if (!this.agreements.includes('privacy')) {
      this.showAgreementWarning = true
      return
    }

    this.placingOrder = true

    try {
      const response = await this.checkoutService.placeOrder(this.cart.id)
      await this.afterOrderPlaced(response)
    } catch (e) {
      logger(e, 'warn')
      this.globalError = (e as Error).message
    }

    this.placingOrder = false
  }

  public setWarning (value: boolean): void {
    if (value) {
      this.showAgreementWarning = false
    }
  }

  public translateToCartItem = translateToCartItem

  protected async afterOrderPlaced (order: OrderResponse): Promise<void> {
    try {
      if (this.agreements.includes('newsletter')) {
        if (this.eventBus && this.checkoutPayload?.user.email) {
          this.eventBus.emit('app:newsletter.subscribe', {
            email: this.checkoutPayload?.user.email, name: ''
          })
        }
      }
    } catch (e) {
      logger(e, 'warn')
    }

    try {
      await this.saveOrderData(order)

      this.isSubmitted = true
      await this.handlePayment(order)

      this.$nextTick(() => this.deleteCart())
    } catch (e) {
      logger(e, 'warn')
    }
  }

  protected async saveOrderData (order: OrderResponse): Promise<void> {
    if (!this.cart || !this.cart.selectedShippingMethod) {
      throw new Error('Missing or invaild cart')
    }
    // ,
    //   cart: this.cart,
    //     deliveryTime: this.deliveryTime,
    //     applied_coupon_code: this.currentCouponCode ? this.currentCouponCode.code : null
    const orderData: OrderData = {
      ...order,
      cart: this.cart,
      ...this.cart.selectedShippingMethod.deliveryTime,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      total: this.$options.filters!.currency(this.cart.getTotalPrice())
    }

    localStorage.setItem(ORDER_KEY, JSON.stringify(orderData))
  }

  protected async handlePayment (order: OrderResponse) {
    const redirectToThankYouPage = async () => {
      this.placingOrder = false
      return await this.$router.push({ name: `checkout.${RouteName.ThankYou}` })
    }

    const redirectToErrorPage = async (code: string) => {
      this.placingOrder = false
      return await this.$router.push({ name: `checkout.${RouteName.ThankYou}`, params: { code } })
    }

    const redirectToGateway = async (url?: string) => {
      if (!url) {
        return redirectToThankYouPage()
      }

      return window.location.assign(url)
    }
    if (!order.paymentMethod) {
      return await redirectToThankYouPage()
    }

    switch (order.paymentMethod) {
      // todo: pozostale metody platnosci
      case PaymentMethodCode.PayU: {
        if (!order.payuRedirectUri) {
          throw new Error('Missing required payuRedirectUri!')
        }

        return await redirectToGateway(order.payuRedirectUri)
      }
      case PaymentMethodCode.PayNow: {
        if (!order.paynowRedirectUri) {
          throw new Error('Missing required paynowRedirectUri!')
        }

        return await redirectToGateway(order.paynowRedirectUri)
      }
      case PaymentMethodCode.Przelewy24: {
        if (!order.przelewy24PaymentUrl) {
          throw new Error('Missing required przelewy24PaymentUrl!')
        }

        return await redirectToGateway(order.przelewy24PaymentUrl)
      }
      case PaymentMethodCode.Blik:
        try {
          alert('blik') // todo: blik
          // await openBlikModal();

          return await redirectToThankYouPage()
        } catch (e) {
          return await redirectToErrorPage(e as string) // todo
        }
      default:
        return await redirectToThankYouPage()
    }
  }

  @Watch('cart')
  protected initCheckout () {
    this.updateCheckoutFromCart()
  }

  protected moveToStep (step: number, force: boolean = false): void {
    this.$router[force ? 'replace' : 'push'](
      { name: `${this.$route.name}`, query: { step: `${step}` } }
    )
  }

  protected setLoading (value: boolean): void {
    this.isLoading = value
  }

  protected updateCheckoutFromCart (): void {
    const translatedCart = translateCartToCheckoutPayload(this.cart)

    if (!translatedCart) {
      if (!this.cartLoading && !this.isWaitingForAuth) {
        this.$router.push({ name: `checkout.${RouteName.Cart}` })
      }

      return
    }

    this.checkoutPayload = {
      ...this.checkoutPayload,
      ...translatedCart,
      acceptPrivacy: this.agreements.includes('privacy'),
      isSignupRequested: this.checkoutPayload?.isSignupRequested ?? false,
      isUserLoggedIn: (this.checkoutPayload?.isUserLoggedIn || this.isLoggedInUser) ?? false
    }
  }

  protected verifyIfStepSet (): void {
    if (typeof this.$route.query.step !== 'string' || this.$route.query.step.length === 0) {
      this.moveToStep(1, true)
      return
    }

    const stepCandidate: number = parseInt(this.$route.query.step)
    if (stepCandidate < 1 || stepCandidate > 3) {
      this.moveToStep(1, true)
    }
  }
}

export default CheckoutBase
